<template>
  <div class="page_container">
    <div class="img_box">
      <img :src="imageUrl"/>
    </div>
    <!-- 文章列表 -->
    <div class="article_list">
      <ul class="nav_item">
        <li v-for="(item, index) in works" :key="index" @click="skipLink(item)">
          <div class="img_inner_box">
            <img :src="item.imageUrl"/>
          </div>
          <div class="artwork">{{ item.worksName }}</div>
          <div class="artist">{{ item.artist }}</div>
          <el-tooltip effect="light" :visible-arrow="false" placement="bottom" :content="item.comments"
                      popper-class="popper-text-class">
            <div class="describe">{{ item.comments }}</div>
          </el-tooltip>

          <span class="split"></span>
          <div class="tools_tip">
            <span>{{ item.displayPlat }}</span>
            <span>{{ item.linkTime }}</span>
          </div>

        </li>
      </ul>
    </div>
  </div>
</template>

<script>
let timer;

export default {
  name: "index",
  data: () => ({
    works: [],
    pageInfo: {
      totalPage: 100,
      currentPageSize: 12,
      currentPage: 1
    },
    imageUrl: ''
  }),
  created() {
    this.$store.commit('setMenuActive', 4)
  },
  beforeMount() {
    let params = {
      currentPage: 1,
      currentPageSize: 12
    }
    this.getList(params)

  },
  mounted() {
    window.onscroll = () => {
      var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
      var windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
      var scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop + windowHeight >= scrollHeight - 300) {
        let params = {
          currentPageSize: 12,
          currentPage: this.pageInfo.currentPage + 1
        }
        this.throttle(params)
      }
    };
  },

  methods: {
    getList(params) {
      if (this.pageInfo.totalPage < params.currentPage) return
      this.axios.get('/sys/nftworks/list', {params}).then(res => {
        let result = this.works.slice()
        this.works = result.concat(res.page.list)
        this.imageUrl = res.imageUrl
        this.pageInfo = {
          totalPage: res.page.totalPage,
          currentPageSize: res.page.pageSize,
          currentPage: res.page.currPage
        }
      })
    },
    throttle(params) {
      // 防抖
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        this.getList(params)
        timer = undefined;
      }, 100);
    },
    // eslint-disable-next-line no-unused-vars
    skipLink(item) {
      // window.open(item.linkUrl)
    }
  },
};
</script>

<style lang="scss" scoped>


.page_container {
  background: #ffffff;
  border-radius: 4px;
  padding: 20px 40px;

  .img_box {
    width: 100%;
    height: 340px;
    padding-bottom: 20px;
    border-bottom: 1px solid #efefef;

    img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }

  .article_list {
    background: #ffffff;
    border-radius: 4px;
    padding: 20px 0;

    .nav_item {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 20px 0;
      font-size: 0.8rem;
      line-height: 1.2rem;


      li {
        width: 300px;
        padding: 0 0 20px !important;
        border-radius: 4px;
        cursor: pointer;
        margin-bottom: 40px;
        position: relative;
        font-size: 14px;
        color: #999999;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);

        div:not(:last-child) {
          line-height: 1.6rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .artwork {
          font-size: 16px;
          font-weight: bold;
          color: #333333;
        }

        .artist {
          color: #666666;
        }

        .split {
          width: 100%;
          height: 1px;
          position: relative;
          display: block;
          margin: 8px 0;
          background-color: #F0F0F0;;
        }

        .tools_tip {
          display: flex;
          justify-content: space-between;
        }

        .img_inner_box ~ div {
          margin: 0 20px;
          box-sizing: border-box;
        }

        .img_inner_box {
          width: 100%;
          height: 280px;
          box-sizing: border-box;
          margin-bottom: 10px;
          font-size: 0;
          line-height: 0;

          img {
            display: block;
            font-size: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          }
        }
      }

      li:hover {
        bottom: 3px;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);
      }

      li:last-child:nth-child(3n - 1) {
        margin-right: 350px;
      }

    }
  }
}
</style>
